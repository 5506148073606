<template>
  <div class="content-container">
    <Header v-if="this.$store.state.user.token" />
    <router-view />
  </div>
</template>

<script>
import Header from "./components/Header.vue";
export default {
  name: "App",
  components: {
    Header,
  },
};
</script>

<style>
#app {
  padding-bottom: 186px;
}
.header-wrap {
  background-color: #fff;
  padding: 16px 0;
  height: 86px;
}

.menu-wrap {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  width: 280px;
  height: 100%;
}

.content-container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
}
</style>
