import { createApp } from "vue";
import App from "./App.vue";

import { router } from "./router";
import { store } from "./store";

import * as Utils from "@/util";

import axios from "axios";

import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

axios.defaults.baseURL = "https://api.hankan.kr";

const app = createApp(App);

app.config.globalProperties.axios = axios;

app.config.globalProperties.$utils = Utils;

app.component("VueDatePicker", VueDatePicker);

app.use(router).use(store).mount("#app");
